import { createDirectus, createItem, rest, readSingleton, staticToken, readItems } from '@directus/sdk';
import type { CustomDirectusTypes } from "/src/types.d.ts";
import * as sampleData from '/src/lib/cms/mockups.js';
import {replaceLiterals, generateSlug} from '/src/lib/generalHelpers.ts';
import {getCollectionCache, loadFromCache, storeToCache} from '/src/lib/cms/cache.ts';
import * as mappingFuncs from '/src/lib/cms/dataMappingFunctions';
import config from '/src/config.ts'


export const DYNAMIC_PLUGIN_DEF : any = {  
  "projectCategories": {
    layout: "projectscategory",
    slugVariable: "projectCategorySlug"
  },
  "projects": {
    layout: "projectsproject",
    slugVariable: "projectSlug"
  },
  "locations": {
    layout: "location",
    slugVariable: "locationSlug"
  },
  "news": {
    layout: "newsItem",
    slugVariable: "newsSlug"
  }
}


//define content filter
const cf = (mainField: string, depth : string = ".*.*.*.*.*") => { 

  let levels = mainField.split(".");
  let res = [
    `${mainField}${depth}`,
    //`${mainField}.item.cover_items.*.*.*.*.*.*`,
  ];

  while(levels.length > 1) {
    levels = levels.slice(0, -1);
    //console.log("cf", {levels});
    res.push(`${mainField}${levels.join(".")}.*.*.*.*`);
  }
  //console.log("cf", {res});
  return res;
}

const cfContent = () => { 
  let filter : any =  [
      'content_main.*.*.*.*.*',
      'content_main.item.*.*.*.*.*',
      'content_main.item.buttons.buttons_id.*.*.*.*.*',
      'content_main.item.content_main.item.*.*.*.*',
      'content_main.item.prefeatures.item.*.*.*.*',
      'content_main.item.tabs.tab_id.*.*',
      'content_main.item.tabs.tab_id.content_main.item.*.*.*.*.*',
      'content_main.item.history_items.history_item_id.*.*',
      'content_main.item.history_items.history_item_id.content_main.item.*.*.*.*',
      'content_main.item.ogranigram_items.organigram_item_id.*.*',
      'content_main.item.ogranigram_items.organigram_item_id.content_main.item.*.*.*.*'
  ];
  return filter;
}

const cfTabs = () => { 
  let filter =  [
      'tabs.tab_id.*.*',
      'tabs.tab_id.content_main.*.*',
      'tabs.tab_id.content_main.item.*.*.*.*.*',
      'tabs.tab_id.content_main.item.tabs.tab_id.content_main.*.*.*.*',
  ];
  return filter;
}


const QUERY_LAYOUT : any = {
  "default": {
    //qFields: ["*", "translations.*.*", "seo.*.*", ...cfContent(),"cover_slider.cover_id.*.*.*.*"],
    qFields: [
      '*',
      'translations.*.*',
      'seo.*.*',
      ...cfContent(),
      'cover_slider.cover_id.*.*.*'
    ],
    sections: ["content_main", "cover_slider"],
    dataMappingFunc : mappingFuncs.mapDefaultData,
  },
  "home": {
    qFields: ["*", "translations.*.*", "seo.*.*", ...cfContent(), "cover_slider.cover_id.*.*.*.*"],
    sections: ["content_main", "cover_slider"],
    dataMappingFunc : mappingFuncs.mapDefaultData,
  },
  "projectCategories": {
    qFields: ["*", "translations.*.*", "seo.*.*", "feature.*.*"],
    sections: ["content_main"],
    dataMappingFunc : mappingFuncs.mapProjectCategoryData,
  },
  "projectCategory": {
    qFields: ["*", "translations.*.*", "seo.*.*", ...cfContent(), "feature.*.*", "additional_projects.*.*.*", "news_tags.*.*.*"],
    sections: ["content_main"],
    dataMappingFunc : mappingFuncs.mapDefaultData
  },
  "projects": {
    qFields: ["*.*", "translations.*.*", "seo.*.*", "categories.*.*.*", "tags.*.*.*", "media_items.*.*", ],
    sections: [],
    dataMappingFunc : mappingFuncs.mapProjectData
  },
  "project": {
    qFields: ["*.*", "translations.*.*", "seo.*.*", ...cfTabs(), "categories.*.*.*.*", "media_items.*.*.*", "similar_projects.*.*.*", "tags.*.*.*.*"],
    sections: [],
    dataMappingFunc : mappingFuncs.mapProjectData
  },
  "locations": {
    qFields: ["*.*", "translations.*.*", "seo.*.*", "tags.*.*.*.*", "image.*.*" ],
    sections: ["content_main"],
    dataMappingFunc : mappingFuncs.mapDefaultData
  },
  "location": {
    qFields: ["*.*", "translations.*.*", "seo.*.*", "tags.*.*.*.*", ...cfContent()],
    sections: ["content_main"],
    dataMappingFunc : mappingFuncs.mapDefaultData
  },
  "news": {
    qFields: ["*.*", "translations.*.*", "seo.*.*", "authors.*.*.*.*", "button.*.*.*.*", "tags.*.*.*.*" ],
    sections: ["content_main"],
    dataMappingFunc : mappingFuncs.mapDefaultData
  },
  "newsItem": {
    qFields: ["*.*", "translations.*.*", "seo.*.*", "authors.*.*.*.*", "button.*.*.*.*", "tags.*.*.*.*", ...cfContent() ],
    sections: ["content_main"],
    dataMappingFunc : mappingFuncs.mapDefaultData
  },
  "events": {
    qFields: ["*.*", "translations.*.*" ],
    sections: [],
    dataMappingFunc : mappingFuncs.mapDefaultData
  },
  "glossary_item": {
    qFields: ["*.*", "translations.*.*" ],
    sections: [],
    dataMappingFunc : mappingFuncs.mapDefaultData
  },
  // "tabs": {
  //   qFields: ["*.*", ...cf1() ],
  //   sections: ["content_main"],
  //   dataMappingFunc : mappingFuncs.mapDefaultData
  // },

}

interface Schema {    
};

//console.log("#############################", {});
const directus = createDirectus<CustomDirectusTypes>(config?.DIRECTUS_URL || 'http://noserver.sorry:8055').with(rest());

let lastRequestCache = {hash: null, data: null} 
export async function getDataItems(cmsTable: string, id: string|null, layout: string, staticVariables : any, language: string = "de-DE", showMockupData: boolean): Promise<any> {
  console.log("getDataItems START", {cmsTable, id, layout, language, qFields: QUERY_LAYOUT[layout].qFields});
  const limit = 40;
  if(showMockupData) return  QUERY_LAYOUT[layout]?.mockupData;
  const langShort = language.split("-")[0];
  
  

  const cacheId = `${layout === "default" ? "page" : layout}-${langShort}-${id}`;
  
  if(config?.USE_PAGE_CACHE && id) {
    
    let cachedData = await loadFromCache(cacheId);
    console.log("getDataItems loaded from cache", {metaInfo: cachedData?.metaInfo});
    if(cachedData) return cachedData?.data || cachedData;
  }

  if(! QUERY_LAYOUT?.[layout]) console.error("getDataItems page layout not defined", {id, layout, language});
  //console.log("getDataItems #2");
  
  const startDate = new Date();

  const deep = {
    "translations": {
      _filter: {
        languages_code: { _eq: language } as any, // Update the type of `_eq` to `any`
      },
    }
  };
  try {
    let ds : any;
    let allDsData: any = []
    let offset = 0;
    let moreData = true;

    const query : any = {};
    if(id) {
      query["filter"] = {"id" : {'_eq': id }};
      query["limit"] = 1;
    } else query["limit"] = limit;
    
    //const requestHash = createHash(JSON.stringify({query, cmsTable, id, layout, language}));


    while(moreData) {

      //wait a second to avoid too fast requests
      await new Promise(resolve => setTimeout(resolve, 200));
      //console.log("getDataItems #2", {cmsTable, offset, limit, moreData});

      query["offset"] = offset;

      // console.log("getDataItems #2", {fields: QUERY_LAYOUT[layout].qFields, 
      //   query: JSON.stringify(query),
      //   deep: JSON.stringify(deep),
      // });
    
      ds = await directus.request(
        readItems(cmsTable as any, {
          deep,
          fields: QUERY_LAYOUT[layout].qFields,
          ...query,
        } as any) 
      );
      
      
      // if(1===2 && cmsTable === 'pages' && id) {
      //   ds2 = await directus.request(
      //     readItems(cmsTable as any, {
      //       deep: {
      //         "translations": {
      //           _filter: {
      //             languages_code: { _eq: language } as any, // Update the type of `_eq` to `any`
      //           },
      //         }
      //       },
      //       fields: ['*', 'content_main.*.*.*.*'],
      //       ...query,
      //     } as any) 
      //   );
      //   ds = [merge(ds2[0], ds1[0])];
      // } else ds = ds1;
      
      if(ds.length > 0) {
  
        let dsData;
        //if(cmsTable ==="glossary_item") 
        //console.log("getDataItems #3", {fields: QUERY_LAYOUT[layout].qFields, 
          //data1: ds?.map((item: any) => item?.id)
          //data2: ds?.[0]?.content_main?.[4]?.item?.buttons?.[0]?.buttons_id?.translations
        //});
        //console.log("getDataItems #2", {ds, q: JSON.stringify(query)});
        const data = mappingFuncs.setTranslationForData({ds}, language, cmsTable, [cmsTable]);
        //let dsData = mappingFuncs.mapM2Data([ds?.[0]], QUERY_LAYOUT[layout]?.sections, language)?.[0];
        
        if(QUERY_LAYOUT[layout]?.dataMappingFunc) dsData = QUERY_LAYOUT[layout]?.dataMappingFunc(data?.ds, QUERY_LAYOUT[layout]?.sections, language);
        else dsData = data;
        // if(cmsTable === 'pages' && id) console.log("getDataItems #3.1", {fields: QUERY_LAYOUT[layout].qFields, 
        //   //dso: ds1?.[0]?.content_main,
        //   ds1: ds?.[0]?.content_main?.[3]?.item,
        //   dsx: ds?.[0]?.content_main?.[3]?.item,
        //   //dsy: ds?.[0]?.content_main?.[7]?.item?.tabs?.[0]?.tab_id?.content_main?.[0]?.item,
        //   dsDatax: dsData?.[0]?.content_main?.[3]?.content_main?.[0],
        //   //dsDatay: dsData?.[0]?.content_main?.[7]?.tabs?.[0]?.content_main?.[0],
    
        // });
        //saveToJsonFile('./temp.json', ds);
        //console.log("getDataItems #4", {dsData});
        dsData = mappingFuncs.replaceStaticVariables(dsData, staticVariables);
        //if(cmsTable === 'news') console.log("getDataItem #3 ####################: ", {ds, dsData});//field: `${cmsTable}_id`, oldId: dsData?.id, newId: dsData?.[`${cmsTable}_id`]?.id});
        // if(dsData?.[`${cmsTable}_id`]) {
        //   console.log("getDataItem #REPLACE ID: ", {field: `${cmsTable}_id`, oldId: dsData?.id, newId: dsData?.[`${cmsTable}_id`]?.id});
        //   dsData.id = dsData?.[`${cmsTable}_id`]?.id;
    
        // }
        //console.log("getDataItem #FINE: ", {length: dsData?.length, timeDiffSec: (new Date().getTime() - startDate.getTime()) / 1000});
        allDsData = [...allDsData, ...dsData];
        offset += limit;
      } else {
        moreData = false;
      }

      
    }


    if(config?.IS_DEV) console.log("getDataItems->request Info", {
      requestDurationSec: (new Date().getTime() - startDate.getTime()) / 1000,
      collection: cmsTable,
      //layout,
      //deep: JSON.stringify(deep),
      //fields: QUERY_LAYOUT[layout].qFields, 
      //query: JSON.stringify(query),
    });

    if(config?.IS_PROD) console.log("getDataItems->request Info", {
      requestDurationSec: (new Date().getTime() - startDate.getTime()) / 1000,
      collection: cmsTable,
      layout,
      id
    });


    const usedData = {data: allDsData, metaInfo: {cacheId, cachedAt: new Date().toISOString()}};
    //console.log("getDataItem #FINE: ", {usedData});
    if(config?.STORE_TO_PAGE_CACHE && id) storeToCache(cacheId, usedData);
    return usedData.data; // make it available to all components

  } catch (error) {
    console.info("getDataItems ERROR", {error});
    return {};
  }
}

export async function getDataItem(cmsTable: string, id: string, layout: string, staticVariables : any, language: string = "de-DE"): Promise<any> {
  const res : any = await getDataItems(cmsTable, id, layout, staticVariables, language, !config?.DIRECTUS_URL);
  //console.log("getDataItem FIN", {res});
  return res?.[0];
}
 

export async function getGlobals(language: any, showMockupData: boolean): Promise<any> {

  //console.log("############################ getGlobals ##############################", {language, showMockupData});
  if(showMockupData) return {
    id: 1,
    claim: 'Wir wollen die Welt mit unseren Gebäuden besser machen.',
    job_application_slug: '/karriere_machen/',
    projects_slug: '/karriere_machen/',
    website_id: {
      id: 2,
      date_updated: '2024-02-07T12:57:55.398Z',
      translations: []
    },
    languages_code: { code: 'de-DE', name: 'Deutsch', direction: 'ltr' },
    replacementsOb: {}
  };

  let data = {};

  try {
    const globals = await directus.request(
      readSingleton("website", {
        deep: {
          "translations" : {
            _filter: {
                  languages_code: {_eq: language},
            },
          },
        },
        fields: ["*.*.*", { translations: ["*"] }],
        limit: 1,
      })
    );

    //console.log("getGlobals #2", globals);

    const globalLinks : any = {};
    const globalTexts : any = {};
    Object.entries(globals).map(([key, value]: [string, any ]) => {
      //console.log("ServicesInteractive", {key});
      if(key.startsWith("link_")) globalLinks[key] = value;
    });
    Object.entries(globals).map(([key, value]: [string, any ]) => {
      //console.log("ServicesInteractive", {key});
      if(key.startsWith("text_")) globalTexts[key] = value;
    });
    //console.log("getGlobals #3", {globalLinks});
    data = {links: globalLinks, texts: globalTexts, ...globals?.translations?.[0]};
    const langReplStr = data?.replacements; 
    const langReplAr = langReplStr.split("\n");
    const langReplOb = {};
    langReplAr.forEach((item : string) => {
        const [name, value] = item.split("=");
        if(name && value) langReplOb[`\{\{${name?.trim()}\}\}`]=value?.trim();
    });
    //console.log("------- getGlobals", {res: {...data, replacementsOb: langReplOb}});
    return {...data, replacementsOb: langReplOb}; // make it available to all components

  } catch (error) {
    console.info('getGlobals', error);
  }
}

export const getPagesStaticPaths = (language: any, SSR: boolean, showMockupData: boolean = false) => {
  
  return async function getStaticPaths() {
    //console.log("getStaticPaths->slug #F", {language, showMockupData});
    const startDate = new Date();
    const langShort = language.split("-")[0];
    if(showMockupData) {
      //console.log("getStaticPaths->slug #F", {env: import.meta, data: sampleData.pages(language, true).map((item: any) => item?.params?.slug)});
      return sampleData.pages(language, false);
    }

    try {
      const pages = await directus.request(
        readItems("pages",
        {
          deep: {
            "translations" : {
              _filter: {
                    languages_code: {_eq: language},
              }
            },
          },
          // @ts-expect-error - we need to fix the types in the SDK
          fields: ["*", { content_main: ["*.*.*"]}, { "navigation": ["*.*.*"]}],
          filter: {
            status: {_eq: "published"},
          },
      }));
      //console.log("getPagesStaticPaths #2: ", {timeDiffSec: (new Date().getTime() - startDate.getTime()) / 1000});
      //console.log("getStaticPaths->slug", {page: pages?.[2]});
      const dynamicPaths : any = [];
      const pagesDS = pages.reduce((pagesDS : any, page : any) => {
        //if(page?.navigation?.plugin) console.log("getStaticPaths->slug #1", {title: page?.navigation?.title});
        if(page?.navigation?.plugin) { // real page or dynamic content
          dynamicPaths.push({ path: SSR ? `/${langShort}${page?.navigation?.path}` : `${page?.navigation?.path}`, plugin: page?.navigation?.plugin});
        } else {
          // add static paths
          let slugJoined : any = SSR ? `/${langShort}${page?.navigation?.path}` : `${page?.navigation?.path}`;
          slugJoined = ['/', ''].includes(slugJoined) ?  undefined : slugJoined; // index needs to be undefined
          //console.log("getStaticPaths->slug #4->push", {page});
          pagesDS.push({
            params: { slug: slugJoined },
            props: { ...page, test:"eins", language, slug: slugJoined },
          });
        }
        return pagesDS;
      }, []);
      //console.log("getPagesStaticPaths #3: ", {timeDiffSec: (new Date().getTime() - startDate.getTime()) / 1000});
      
      //pagesDS.push({ params: { slug: undefined }, props: { language, layout: 'home' } });
      //console.log("getStaticPaths->slug #3", {dynamicPaths});
      if(dynamicPaths.length > 0) {
        let slugField = "slug";

        for (const dynPath of dynamicPaths) {
          let dynItems;
          // add pages for project categories at path
          
          switch(dynPath?.plugin) {
            case "projectCategories":
              //console.log("getNavigationMapping #4", {anItem});
              dynItems = await getCollectionCache('projectCategories', {}, {language});
              //console.log("getPagesStaticPaths #3.1: ", {timeDiffSec: (new Date().getTime() - startDate.getTime()) / 1000});
              break;
            case "projects":
              //console.log("getNavigationMapping #4->project", {item});
              dynItems = await getCollectionCache('projects', {}, {language})
              //console.log("getPagesStaticPaths #3.2: ", {timeDiffSec: (new Date().getTime() - startDate.getTime()) / 1000});
              break;
            case "locations":
              //console.log("getNavigationMapping #4", {anItem});
              dynItems = await getCollectionCache('locations', {}, {language})
              //console.log("getPagesStaticPaths #3.2: locations# ", {items: dynItems.map(aitem => aitem?.locations_id), timeDiffSec: (new Date().getTime() - startDate.getTime()) / 1000});
              break;
            case "news":
              //console.log("getNavigationMapping #4", {anItem});
              dynItems = await getCollectionCache('news', {}, {language})
              //console.log("getPagesStaticPaths #3.2: locations# ", {items: dynItems.map(aitem => aitem?.locations_id), timeDiffSec: (new Date().getTime() - startDate.getTime()) / 1000});
              break;
            default:
          }
          
          if(dynItems) {
            const slugTemplate = getDynamicPageSlug(dynPath?.path, dynPath?.plugin);
            dynItems?.forEach((item : any) => {
              //if(dynPath?.plugin === "projects") console.log("getNavigationMapping #4->project", {selSlug: item?.slug ||item?.name || item?.caption, slug: item?.slug, name: item?.name, caption: item?.caption});

              const slug = replaceLiterals(slugTemplate,{[DYNAMIC_PLUGIN_DEF?.[dynPath?.plugin]?.slugVariable]: generateSlug(item?.slug ||item?.name || item?.caption)});
              //if(dynPath?.plugin === "projectCategories") console.log("getStaticPaths->slug #4->push location ######", {slug: item?.slug, name: item?.name, caption: item?.caption, slug1: generateSlug(item?.slug || item?.name), slug2: slug});
              //if(dynPath?.plugin === "news") console.log("getStaticPaths->slug #4->push news ######", {id: item?.id, locid: item, name: item?.name, caption: item?.caption, slug: generateSlug(item?.name || item?.caption)});
              //if(dynPath?.plugin === "projects" && item?.name?.includes("hrlich")) console.log("getStaticPaths->slug #4->push projects ######", {language, id: item?.id, name: item?.name, usedSlugBase: item?.slug ||item?.name || item?.caption, slug: generateSlug(item?.name || item?.caption)});
              
              pagesDS.push({ params: {slug}, props: { id: item?.id, item, slug, language, layout: DYNAMIC_PLUGIN_DEF?.[dynPath?.plugin]?.layout } });
            });
          }
          //console.log("getPagesStaticPaths #3.3: ", {timeDiffSec: (new Date().getTime() - startDate.getTime()) / 1000});

        };
      }
      //console.log("getPagesStaticPaths #FIN: ", {timeDiffSec: (new Date().getTime() - startDate.getTime()) / 1000});
      
      
      //const usedPagesDS = pagesDS.filter((item: any) => item?.params?.slug?.includes("atp") || item?.params?.slug == undefined);
      //console.log("getStaticPaths->slug #L", {aPage: usedPagesDS?.map((item: any) => item?.props)});
      return pagesDS;
    } catch (error) {
      console.info("getStaticPaths", {error});
      
    }
  }
};


export async function getNavigationMapping(language: string = "de-DE", dynamicPaths : any, showMockupData: boolean): Promise<any> {
  //console.log("#### getNavigationMapping", {language, data: dynamicPaths?.projects?.[0]});
  const langShort = language.split("-")[0];
  if(showMockupData) return {navigation: sampleData.navigation, dynamicPageTargets: {"projectCategories": ["/projekte/${projectCategorySlug}"]}};

  let langTitle = "title";
  let langSubTitle = "sub_title";

  if(language.includes("en")) {
    langTitle = "title_en";
    langSubTitle = "sub_title_en";
  }

  try {
    const navigationItems = await directus.request(
      readItems("navigation", {
        sort: ['sort'],
        // @ts-expect-error - we need to fix the types in the SDK
        fields: ["*.*", {"navigation_items": "*.*.*"}, {"parent_navigation": "*.*.*"}],
      })
    );

    const navigation: { [key: string]: any } = {};
    const dynamicPageTargets: { [key: string]: any } = {};
    //console.log("#### getNavigationMapping", {dynPaths: dynamicPaths, items: navigationItems?.map((item: any) => { return {title: item?.title, plugin:item?.plugin}})});
    navigationItems.forEach((anItem: any) => {
      if(anItem?.plugin) {
      //console.log("getNavigationMapping->dyn", {itemData: anItem.plugin});
        
        switch(anItem?.plugin) {
          case "projects":
          case "projectCategories":
          case "locations":
          case "news":
            if(dynamicPaths?.[anItem?.plugin]) {
              const slugTemplate = getDynamicPageSlug(`/${langShort}${anItem?.path}`, anItem.plugin);
              if(!dynamicPageTargets[anItem.plugin]) dynamicPageTargets[anItem.plugin] = [slugTemplate];
              else dynamicPageTargets[anItem.plugin].push(slugTemplate);
              //console.log("getNavigationMapping #4", {data: dynamicPaths?.[anItem.plugin]});
              dynamicPaths?.[anItem.plugin]?.forEach((dynItem : any) => {
                const slug = replaceLiterals(slugTemplate,{[DYNAMIC_PLUGIN_DEF?.[anItem?.plugin]?.slugVariable]: generateSlug(dynItem?.slug || dynItem?.name || dynItem?.caption)});
                //if(anItem?.plugin === "news") console.log("getNavigationMapping->dynSlug #3", {slug, name: dynItem?.name});
                if(["projectCategories"].includes(anItem?.plugin)) navigation[slug] = {title: dynItem?.caption || dynItem?.name, subTitle: "", backgroundImage: null, bigApplyButton: null};
              });
            }
            break;
          case "forwarding":
            //console.log("getNavigationMapping->forwarding", {anItem});
            navigation[`/${langShort}${anItem?.path}`] = {title: anItem[langTitle], hidden: anItem?.hidden, subTitle: anItem[langSubTitle], backgroundImage: anItem?.background_image, bigApplyButton: anItem?.big_apply_button, forwardTo: anItem?.forward_to};
            break;
          default:
        }
        
      } else navigation[`/${langShort}${anItem?.path}`] = {title: anItem[langTitle], hidden: anItem?.hidden, subTitle: anItem[langSubTitle], backgroundImage: anItem?.background_image, bigApplyButton: anItem?.big_apply_button};
    });

    //console.log("getNavigationMapping", {dynamicPageTargets});
    return {navigation, dynamicPageTargets};

  } catch (error) {
    console.info("getNavigationMapping", {error});
  }

}

export async function getMarginalNavigationMapping(language: string, showMockupData: boolean): Promise<any> {
  //console.log("#### getMarginalNavigationMapping", language, showMockupData);
  if(showMockupData) return sampleData.subNavigation;

  try {
    const items = await directus.request(
      readItems("subNavigation", {
        deep: {
          // @ts-expect-error - we need to fix the types in the SDK
          "translations" : {
            _filter: {
                  languages_code: {_eq: language},
            }
          },
        },
        fields: ["*", "link.*.*.*", "translations.*.*.*"],
        filter: {
          status: {_eq: "published"},
        },
        sort: ['sort'],
      })
    );

    const res: { [key: string]: any } = {};

    items?.forEach((anItem: any) => {
      //console.log("xxx", {anItem});
      const {link, translations} = anItem;
      // if(link?.parent_navigation?.url) path.unshift(link?.parent_navigation?.url);
      // if(link?.parent_navigation?.parent_navigation?.url) path.unshift(link?.parent_navigation?.parent_navigation?.url);
      // if(link?.url != '/') path.unshift('');
      res[link?.id] = {title: translations?.[0]?.title, link};
    });

    //console.log("getMarginalNavigationMapping", res);
    return res;

  } catch (error) {
    console.info("getMarginalNavigationMapping", {err: error?.errors?.[0]});
    
  }

}


const getDynamicPageSlug = (path: string, plugin: string) => {
  //console.log("getDynamicPageSlug", {path, plugin});
  if(!path) return path;
  let slugTemplate = path.split("/");
  slugTemplate.pop(); //remove last segment, which is just a placeholder
  return slugTemplate.join("/") + "/${" + DYNAMIC_PLUGIN_DEF?.[plugin]?.slugVariable + "}";
};


export async function sendForm(formData: any, type: string) {

  let collection : string = "form_request";
  switch(type) {
    case "newsletter":
      collection = "form_nl";
      break;
    default:

  }

  try {
    const resonse = await directus.request(
      createItem(collection, formData)
    );
    console.log("sendForm", {formData});
    return resonse;
  } catch (error) {
    console.log("sendForm", error);
    // if (error instanceof Error) {
    //   console.error("Error message:", error.message);
    //   console.error("Error stack:", error.stack);
    // }
  }

}

export async function getLanguagePathItem(itemType : string, itemId : string, language : string) {
  let data : any;

  data = await getCollectionCache('paths', {}, {language});
  //console.log("getLanguageItem", {data: data?.map((item: any) => {return {id: item?.props?.id, type: item?.props?.layout}})});
  const res =  data?.find((item : any) => (item?.props?.id === itemId && item?.props?.layout === itemType));
  //console.log("getLanguagePathItem", {itemType, itemId, res});
  return res?.props || res
}

export async function getLanguageItem(itemType : string, itemId : string, language : string) {
  let data : any;
  switch(itemType) {
    case "projectCategories":
      data = await getCollectionCache('projectCategories', {}, {language});
      break;
    case "projects":
      data = await getCollectionCache('projects', {}, {language});
      break;
    case "locations":
      data = await getCollectionCache('locations', {}, {language});
      break;
    case "news":
      data = await getCollectionCache('news', {}, {language});
      break;
    default:
      data = await getCollectionCache('paths', {}, {language});
      //console.log("getLanguageItem", {data: data?.map((item: any) => item?.props)});
      break;
  }
  const res =  data?.find((item : any) => (item?.props?.id || item?.id) === itemId);
  //console.log("getLanguageItem", {itemType, itemId, res});
  return res?.props || res
}

export default directus;