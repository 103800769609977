// api-cache.js
import config from '/src/config.ts'

export class JobsData {
  constructor() {
    this.baseUrl = config?.SMART_RECRUITERS_API_URL;
    this.cacheTime = 120 * 60 * 1000; // 10 minutes in milliseconds
  }

  async fetchData(url) {
    const response = await fetch(`${url}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  }

  async collectData(processData = (data) => data, endpoint = "") {
    const url = `${this.baseUrl}${endpoint}`;
    const cacheKey = url;
    const cachedData = (typeof window !== 'undefined') ? this.getCachedData(cacheKey) : null;

    if (cachedData) {
      return cachedData;
    }

    try {
      let data = await this.fetchData(url);
      let content = data.content;
      //console.log("collectData ##66 1", {content});
      while (data?.offset + data?.limit < data?.totalFound) {
        data = await this.fetchData(
          `${url}?offset=${data?.offset + data?.limit}`
        );
        //console.log("collectData ##66 x", {content: data.content});
        content = [...content, ...data.content];
      }
      //console.log("collectData ##66 all", {content});

      // get job details in bulk, rather get it on demand
      //content = await this.addJobDetails(content);
      const processedData = processData(content);
      processedData.jobs = processedData?.jobs?.sort((a, b) =>
        a.title.localeCompare(b.title)
        );
      if(typeof window !== 'undefined') this.setCachedData(cacheKey, processedData);
      return processedData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  async addJobDetails(content) {
    const results = [];
    for (const job of content) {
      try {
        const details = job?.ref ? await this.fetchData(job.ref) : null;
        results.push({ ...job, details });
      } catch (error) {
        console.error(`Error fetching details for job ${job?.ref}:`, error);
        throw error; // or handle the error as appropriate
      }
    }
    return results;
  }

  getCachedData(key) {
    const cachedItem = localStorage?.getItem(key);
    if (!cachedItem) return null;

    const { data, timestamp } = JSON.parse(cachedItem);
    if (Date.now() - timestamp > this.cacheTime) {
      localStorage?.removeItem(key);
      return null;
    }

    return data;
  }

  setCachedData(key, data) {
    const cacheItem = JSON.stringify({
      data,
      timestamp: Date.now(),
    });
    localStorage?.setItem(key, cacheItem);
  }

  getValueLabelByFieldId(data, searchKey) {
    const foundItem = data.find((item) => item.fieldId === searchKey);
    let label = foundItem?.valueLabel;
    let value = foundItem?.valueId;
    switch (searchKey) {
      case "65faa3f31bef3c7b8e15be8f":
        label = foundItem?.valueLabel?.split(",")?.[0];
        if (foundItem?.valueLabel.includes("ATP architekten ingenieure")) {
          label = "ATP architekten ingenieure";
          value = "atp-architekten-ingenieure";
        }
        if (foundItem?.valueLabel.includes("ATP architects engineers")) {
          label = "ATP architects engineers";
          value = "atp-architekten-ingenieure";
        }
        break;
      default:
        label = foundItem?.valueLabel;
    }

    return foundItem ? { id: value, label } : "";
  }

  getFilterOptionsForFieldId(data, searchKey) {
    const obData = data.reduce((acc, job) => {
      const label = job?.[searchKey]?.label;
      const key = job?.[searchKey]?.id;
      if (!acc[key]) {
        //console.log("getFilterOptionsForFieldId ##99", {id: job?.[searchKey]?.id, label});
        if (label)
          acc[key] = {
            caption: label,
            counts: 0,
            filterClass: `${searchKey}|${key}`,
            id: job?.[searchKey]?.id,
          };
      }
      if (acc[key]) acc[key].counts++;
      return acc;
    }, {});

    let valuesOnly = Object.values(obData);
    //console.log("getFilterOptionsForFieldId ##99", {valuesOnly});
    return (
      valuesOnly.sort((a, b) => a?.caption?.localeCompare(b?.caption)) || []
    );
  }

  processJobsData(data) {
    //return data;
    const getValueLabelByFieldId = this.getValueLabelByFieldId;
    const jobs = data.map((job) => {
      const customField = job?.customField;
      return {
        id: job.jobAdId,
        title: job.name,
        location: getValueLabelByFieldId(
          customField,
          "65faf15b51a29823cfd7bb36"
        ),
        department: getValueLabelByFieldId(
          customField,
          "65faa3f31bef3c7b8e15be90"
        ),
        startAs: getValueLabelByFieldId(
          customField,
          "65faf1784525e310076a9bbc"
        ),
        company: getValueLabelByFieldId(
          customField,
          "65faa3f31bef3c7b8e15be8f"
        ),
        country: getValueLabelByFieldId(
          customField,
          "COUNTRY"
        ),
        releasedDate: job?.releasedDate,
        applyUrl: job?.details?.applyUrl,
        postingUrl: job?.details?.postingUrl,
        detailsUrl: job?.ref,
      };
    });

    //create an array with unique values of department and the number of jobs in that department
    const filterSettings = {
      filter: [
        {
          type: "department",
          items: this.getFilterOptionsForFieldId(jobs, "department"),
          settings: { multi: true },
        },
        {
          type: "company",
          items: this.getFilterOptionsForFieldId(jobs, "company"),
          settings: { multi: true },
        },
        {
          type: "startAs",
          items: this.getFilterOptionsForFieldId(jobs, "startAs"),
          settings: { multi: true },
        },
        {
          type: "location",
          items: this.getFilterOptionsForFieldId(jobs, "location"),
          settings: { multi: true },
        },
      ],
    };
    return { jobs, filterSettings };
  }

  async getJobDetails(refUrl) {
    const details = refUrl ? await this.fetchData(refUrl) : null;
    return details;
  }

  replaceVariables(noOfJobs) {
    //console.log("replaceVariables ##66", { noOfJobs });
  }

  async getJobs() {
    const data = await this.collectData(this.processJobsData.bind(this));
    return data?.jobs;
  }

  async getFilterSettings() {
    const data = await this.collectData(this.processJobsData.bind(this));
    return data?.filterSettings;
  }

  async getNumberOfJobs() {
    const data = await this.collectData(this.processJobsData.bind(this));
    return data?.jobs?.length;
  }
}
if (typeof window !== 'undefined') {
  if (window?.jobsData === undefined) {
    window.jobsData = new JobsData();
  }

  (async function() {
    try {
      const data = await window.jobsData.getNumberOfJobs();
      document.querySelectorAll(".jobs-number").forEach((element) => {
        element.innerHTML = data;
      });
      console.info("Jobs data fetched and applied!");
    } catch (error) {
      console.error("Error fetching job data:", error);
    }
  })();
}
