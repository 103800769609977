import {
  getGlobals,
  getNavigationMapping,
  getMarginalNavigationMapping,
  getPagesStaticPaths,
  getDataItems
} from "/src/lib/cms/directus.js";
import config from '/src/config.ts'
import { getStore } from "@netlify/blobs";
export const NETLIFY_BLOB_STORAGE = "cmsData";
import {JobsData} from  '/src/components/plugin/jobs/jobsData.js';
import { saveToJsonFile, readJsonFile } from "/src/lib/cms/helper.ts";

const COLLECTION_STORAGE = "dataMain-" + config?.BRANCH;
const LOCAL_CACHE_PATH = "./.local_cache/";

let showMockupData = false;
let language = "de-DE";
let caching = true;

let cmsData: any = {
  steps: 10,
  currentStep: 10,
  lastUpdate: new Date().toISOString(),
  "en-EN": {
    globals: null,
    paths: null,
    navigation: null,
    marginalNavigation: null,
    projectCategories: null,
    projects: null,
    news: null,
    locations: null,
    events: null,
    dynamicPageTargets: null,
    showMockupData: null,
    jobs: null,
  },
  "de-DE": {
    globals: null,
    paths: null,
    navigation: null,
    marginalNavigation: null,
    projectCategories: null,
    projects: null,
    news: null,
    locations: null,
    events: null,
    dynamicPageTargets: null,
    showMockupData: null,
    jobs: null,
  },
};

const getDataStore = () => {

  if(!process.env.NETLIFY_BLOBS_CONTEXT) {
    // console.log("getDataStore->local", {name: NETLIFY_BLOB_STORAGE,
    //   siteID: process.env.SITE_ID,
    //   token: process.env.TOKEN || process.env.NETLIFY_FUNCTIONS_TOKEN});
      try {
        return getStore({
          name: NETLIFY_BLOB_STORAGE,
          siteID: process.env.SITE_ID,
          token: process.env.TOKEN || process.env.NETLIFY_FUNCTIONS_TOKEN,
          consistency: "strong"
          });
        
      } catch (error) {
        console.error('Error in getDataStore:', { 
          error: error?.message,
          info: "possible reason: Wrong Token!",
          name: NETLIFY_BLOB_STORAGE,
          siteID: process.env.SITE_ID,
          token: process.env.TOKEN || process.env.NETLIFY_FUNCTIONS_TOKEN
        });
      }
    
  } else return getStore(NETLIFY_BLOB_STORAGE);
}

export const removeCacheItem = async (name) => {
  //console.log("persistCMSData", {dataId, data});

  if(process.env.NETLIFY_BLOBS_CONTEXT || process.env.NETLIFY_FUNCTIONS_TOKEN) {

    let cmsDataStore = getDataStore();
    await cmsDataStore.delete(name);

    //console.log("reomveCacheItem #FIN", {name});
  } 

}

export const storeToCache = async (dataId : string, data : any) => {
  //console.log("persistCMSData", {d1: process.env.NETLIFY_BLOBS_CONTEXT, d2: process.env.NETLIFY_FUNCTIONS_TOKEN, d0: process.env, });

  if(process.env.NETLIFY_BLOBS_CONTEXT || process.env.NETLIFY_FUNCTIONS_TOKEN) {;
    let cmsDataStore = getDataStore();
    //await cmsDataStore.delete("allData");
    //await cmsDataStore.delete("all");
    //console.log("persistCMSData->saveToBlog", {dataId});
    await cmsDataStore.setJSON(dataId, data, {metadata: {"lastUpdate": new Date().toDateString()}});
  } else {
    //console.log("storeToCache->saveToLocal", {dataId});
    if(data) saveToJsonFile(LOCAL_CACHE_PATH + dataId + ".json", data);
  }
}

export const loadFromCache = async (dataId : string) => {
  //console.log("retrieveCMSData");
  if(process.env.NETLIFY_BLOBS_CONTEXT || process.env.NETLIFY_FUNCTIONS_TOKEN) {
    let cmsDataStore = getDataStore();
    const storeData = await cmsDataStore.get(dataId, {consistency: "strong", type: "json"});
    const metaData = await cmsDataStore.getMetadata(dataId);
    //console.log("retrieveCMSData->getData #FIN", {metaData});
    // if(!storeData?.['de-DE']) return cmsData
    // else cmsData = {...storeData};
    return storeData;
    
  } else {
    
    const fileData = readJsonFile(LOCAL_CACHE_PATH + dataId + ".json");
    //console.log("retrieveCMSData->local data", {dataId, fileData});
    if(fileData) return fileData;

  }
}

export const configCMSData = async (
  newLanguage: string,
  newShowMockupData: boolean,
  cachingDisabled: boolean
) => {
  if (newLanguage) language = newLanguage;
  if (newShowMockupData) showMockupData = newShowMockupData;
  if (cachingDisabled) caching = !cachingDisabled;
};


export const refreshCache = async () => {
  //console.log("cache->refreshCache");
  const newData = await loadFromCache(COLLECTION_STORAGE);
  if(newData) cmsData = newData;
}

export const getCurrentCollectionCache = (type : string) => {
  return cmsData[language][type];
}
export const getCollectionCache = async (
  type : string,
  params: any = {},
  overrides: any = {}
) => {
  const startDate = new Date();
  const usedLanguage = overrides?.language || language;

 
  if(!showMockupData && !cmsData?.[usedLanguage]?.['globals']) {
    const newData = await loadFromCache(COLLECTION_STORAGE);
    if(newData) cmsData = newData;
  }
  //console.log("getCMSData #1" cmsData?.[usedLanguage]?.[type]);
  if(cmsData?.[usedLanguage]?.[type] && overrides?.forceRefresh) {
    cmsData[usedLanguage][type] = undefined;
    console.log("getCMSData----> FORCE_REFRESH: ", { forceRefresh: overrides?.forceRefresh, type, usedLanguage, data: cmsData?.[usedLanguage]?.[type] });
  }
  if(overrides?.steps) {
    cmsData.currentStep = overrides.currentStep;
    if(overrides.currentStep === 1) cmsData.lastUpdate = new Date().toISOString();

  }
  //if(type === "globals") console.log("############# getCMSData #############", { caching, type, overrides });
  if (!caching || !cmsData?.[usedLanguage]?.[type]) {
    console.log("getCMSData----> FETCHING: ", {
      type
    });
    // console.log("getCMSData----> FETCHING: ", {
    //   type,
    //   lang: usedLanguage,
    //   mockup: overrides?.showMockupData || showMockupData,
    // });
    switch (type) {
      case "steps":
        return {currentStep: cmsData.currentStep, steps: cmsData.steps, lastUpdate: cmsData.lastUpdate};
      case "globals":
        //console.log("############# getCMSData->getGlobals #############", { type, params, overrides });
        cmsData[usedLanguage][type] = await getGlobals(
          usedLanguage,
          overrides?.showMockupData || showMockupData
        );
        break;
      case "paths":
        //console.log("getCMSData->paths #1", { type, params, overrides });
        cmsData[usedLanguage][type] = await getPagesStaticPaths(usedLanguage, true, overrides?.showMockupData || showMockupData)();
        break;
      case "jobs":
        //console.log("getCMSData->paths #1", { type, params, overrides });
        const JobsAPI = new JobsData();
        const allJobs = await JobsAPI.getJobs();
        for (const job of allJobs) {
          job["details"] = await JobsAPI.getJobDetails(job.detailsUrl);
        }
        //console.log("getCMSData->jobs #1", { jobs });
        cmsData[usedLanguage][type] = allJobs;
        break;
      case "navigation":
      case "dynamicPageTargets":
        if (!cmsData[usedLanguage]["projectCategories"])
          console.info("getCMSData->navigation PROJECTCATEGORIES are mssing!");

        // console.info("getCMSData->navigation->dynPaths", {
        //   projectCategories: cmsData[usedLanguage]["projectCategories"],
        //   //projects: cmsData[usedLanguage]["projects"],
        //   locations: cmsData[usedLanguage]["locations"],
        //   news: cmsData[usedLanguage]["news"],
        // });
        const { navigation, dynamicPageTargets } = await getNavigationMapping(
          usedLanguage,
          {
            projectCategories: cmsData[usedLanguage]["projectCategories"],
            projects: cmsData[usedLanguage]["projects"],
            locations: cmsData[usedLanguage]["locations"],
            news: cmsData[usedLanguage]["news"],
          },
          overrides?.showMockupData || showMockupData
        );

        cmsData[usedLanguage]["navigation"] = navigation;
        cmsData[usedLanguage]["dynamicPageTargets"] = dynamicPageTargets;
        break;
      case "marginalNavigation":
        cmsData[usedLanguage][type] = await getMarginalNavigationMapping(
          usedLanguage,
          overrides?.showMockupData || showMockupData
        );
        break;
      case "projectCategories":
        if (!cmsData[usedLanguage]["globals"]?.replacementsOb)
          console.info("getCMSData->projectCategories GLOBALS are mssing!");
        else
          //console.info("getCMSData->projectCategories #1");
          cmsData[usedLanguage][type] = await getDataItems("project_categories",null,"projectCategories", 
            cmsData[usedLanguage]["globals"]?.replacementsOb,
            usedLanguage,
            overrides?.showMockupData || showMockupData
          );
          // cmsData[usedLanguage][type] = await getProjectCategories(
          //   usedLanguage,
          //   cmsData[usedLanguage][type]?.replacementsOb,
          //   overrides?.showMockupData || showMockupData
          // );
          //console.info("getCMSData->projectCategories #2", {data: cmsData[usedLanguage][type]});
        break;
      case "projects":
        if (!cmsData[usedLanguage]["globals"]?.replacementsOb)
          console.info("getCMSData->projects GLOBALS are mssing!");
        else {
          let projectsRaw = await getDataItems("projects",null,"projects", 
            cmsData[usedLanguage]["globals"]?.replacementsOb,
            usedLanguage,
            overrides?.showMockupData || showMockupData
          );
          cmsData[usedLanguage][type] = projectsRaw?.sort((a : any, b : any) => {
            //console.log("getCMSData->news #SORT", {a: a.date, b: b.date, aD: createDateFromGermainString(a.date), bD: createDateFromGermainString(b.date)});  
            return new Date(b.date) - new Date(a.date);
          });
        }
        break;
      case "news":
        if (!cmsData[usedLanguage]["globals"]?.replacementsOb)
          console.info("getCMSData->projects GLOBALS are mssing!");
        else {
          let newsRaw = await getDataItems("news",null,"news", 
            cmsData[usedLanguage]["globals"]?.replacementsOb,
            usedLanguage,
            overrides?.showMockupData || showMockupData
          );

          cmsData[usedLanguage][type] = newsRaw?.sort((a : any, b : any) => {
            //console.log("getCMSData->news #SORT", {a: a.date, b: b.date, aD: createDateFromGermainString(a.date), bD: createDateFromGermainString(b.date)});  
            return new Date(b.date) - new Date(a.date);
          });

        }

           
          // cmsData[usedLanguage][type] = await getLocations(
          //   usedLanguage,
          //   cmsData[usedLanguage]["globals"]?.replacementsOb,
          //   overrides?.showMockupData || showMockupData
          // );
        break;
      case "locations":
        if (!cmsData[usedLanguage]["globals"]?.replacementsOb)
          console.info("getCMSData->projects GLOBALS are mssing!");
        else
          cmsData[usedLanguage][type] = await getDataItems("locations",null,"locations", 
            cmsData[usedLanguage]["globals"]?.replacementsOb,
            usedLanguage,
            overrides?.showMockupData || showMockupData
          );
          // cmsData[usedLanguage][type] = await getLocations(
          //   usedLanguage,
          //   cmsData[usedLanguage]["globals"]?.replacementsOb,
          //   overrides?.showMockupData || showMockupData
          // );
        break;
      case "events":
        if (!cmsData[usedLanguage]["globals"]?.replacementsOb)
          console.info("getCMSData->projects GLOBALS are mssing!");
        else
          cmsData[usedLanguage][type] = await getDataItems("events",null,"events", 
            cmsData[usedLanguage]["globals"]?.replacementsOb,
            usedLanguage,
            overrides?.showMockupData || showMockupData
          );
          // cmsData[usedLanguage][type] = await getLocations(
          //   usedLanguage,
          //   cmsData[usedLanguage]["globals"]?.replacementsOb,
          //   overrides?.showMockupData || showMockupData
          // );
        break;
      case "glossary":
        const glossaryItems = await getDataItems("glossary_item",null,"glossary_item", 
          cmsData[usedLanguage]["globals"]?.replacementsOb,
          usedLanguage,
          overrides?.showMockupData || showMockupData
        );
        cmsData[usedLanguage][type] = glossaryItems?.sort((a : any, b : any) => {
          //console.log("getCMSData->news #SORT", {a: a.date, b: b.date, aD: createDateFromGermainString(a.date), bD: createDateFromGermainString(b.date)});  
          return a.title > b.title ? 1 : -1;
        });
        break;
      // case "tabs":
      //     cmsData[usedLanguage][type] = await getDataItems("tab",null,"tabs", 
      //       cmsData[usedLanguage]["globals"]?.replacementsOb,
      //       usedLanguage,
      //       overrides?.showMockupData || showMockupData
      //     );
      //   break;
      default:
        cmsData[usedLanguage][type] = null;
        break;
    }
    if(overrides?.currentStep) cmsData.currentStep = overrides.currentStep;
    if(!showMockupData) await storeToCache(COLLECTION_STORAGE, cmsData);
  }
  const timeDiff = new Date().getTime() - startDate.getTime();
  //console.log("----> getCMSData: ", { type, timeDiff });
  return cmsData[usedLanguage][type];
};
